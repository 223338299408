.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.feedback .tip-box {
  width: 100%;
  height: 0.96rem;
  background: var(--main-color);
  line-height: 0.96rem;
  padding: 0 0.24rem;
  font-size: 0.32rem;
  color: #fff;
}
.feedback yd-textarea {
  padding: 0.24rem 0;
}
.feedback .yd-textarea > textarea {
  height: 3.16rem;
  font-size: 0.32rem;
  color: #333;
}
.feedback .yd-textarea > textarea::-webkit-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.feedback .yd-textarea > textarea::-moz-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.feedback .yd-textarea > textarea:-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.feedback .yd-textarea > textarea::-ms-input-placeholder {
  font-size: 0.32rem;
  color: #999;
}
.feedback .yd-textarea > textarea::placeholder {
  font-size: 0.32rem;
  color: #999;
}
.feedback .save-box {
  width: 100%;
  height: 0.88rem;
  padding: 0 0.24rem;
  margin-top: 0.4rem;
}
.feedback .save-btn {
  width: 100%;
  height: 100%;
  background: var(--main-color);
  border-radius: 1rem;
  line-height: 0.88rem;
  font-size: 0.32rem;
  color: #fff;
  text-align: center;
}
